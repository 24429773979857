<template>
  <b-card :title="$t('Onay Bekleyen Siparişler')">
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in ordersData"
        :key="index"
        :to="'/admin/siparisler/goruntule/' + item.id"
      >
        <b-row>
          <b-col cols="auto">
            <b-avatar
              size="64"
              variant="primary"
            >
              <feather-icon icon="ShoppingCartIcon" />
            </b-avatar>
          </b-col>
          <b-col>
            <div class="font-weight-bold text-primary">
              {{ $t('Sipariş No') }}: {{ item.id }}
            </div>
            <div class="font-weight-light">
              {{ item.member }}
            </div>
            <div class="font-small-2">
              {{ $t('Toplam') }}: {{ item.total | toCurrency }} ₺
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import {
  BCard, BAvatar, BListGroup, BListGroupItem, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'PendingOrders',
  components: {
    BCard, BAvatar, BListGroup, BListGroupItem, BRow, BCol,
  },
  props: {
    ordersData: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style scoped></style>
