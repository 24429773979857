<template>
  <b-card :title="$t('Onay Bekleyen Girişimler')">
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in startupsData"
        :key="index"
        :to="'/admin/startup-applications/pre-application/' + item.id"
      >
        <b-row>
          <b-col cols="auto">
            <b-avatar
              v-if="!item.image"
              :text="item.avatar_text"
              size="64"
              variant="primary"
            />
            <b-avatar
              v-else
              :src="item.image"
              size="64"
              variant="primary"
            />
          </b-col>
          <b-col>
            <div class="font-weight-bold text-primary">
              {{ item.title }}
            </div>
            <div class="font-weight-light">
              {{ item.company }}
            </div>
            <div class="font-small-2">
              {{ item.description }}
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BListGroup, BListGroupItem, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'PendingStartups',
  components: {
    BCard, BAvatar, BListGroup, BListGroupItem, BRow, BCol,
  },
  props: {
    startupsData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
