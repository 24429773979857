<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
      >
        <counters
          :label="$t('Yatırıma Açık Girişim')"
          :count-value="dashboardData.startups"
          icon="EyeIcon"
          color="primary"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <counters
          :label="$t('Yatırımcı')"
          :count-value="dashboardData.investors"
          icon="BarChart2Icon"
          color="danger"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <counters
          :label="$t('Girişim Başvurusu')"
          :count-value="dashboardData.entrepreneurs"
          icon="AwardIcon"
          color="success"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
      >
        <counters
          :label="$t('Başvuru')"
          :count-value="dashboardData.pending_startups_count"
          icon="FileIcon"
          color="info"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <counters
          :label="$t('Onaylanan Başvuru')"
          :count-value="dashboardData.approved_startups_count"
          icon="CheckIcon"
          color="success"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <counters
          :label="$t('Onay Bekleyen Başvuru')"
          :count-value="dashboardData.pending_startups_count"
          icon="ClockIcon"
          color="danger"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-if="dashboardData.pending_startups.length"
        cols="12"
      >
        <pending-startups :startups-data="dashboardData.pending_startups" />
      </b-col>
      <b-col
        cols="12"
      >
        <latest-blog-posts :blog-data="dashboardData.latest_blog_posts" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import Counters from '@/views/Dashboard/components/Counters.vue'
import LatestBlogPosts from '@/views/Dashboard/components/LatestBlogPosts.vue'
import PendingStartups from '@/views/Dashboard/components/PendingStartups.vue'

export default {
  name: 'Investor',
  components: {
    BRow,
    BCol,
    Counters,
    LatestBlogPosts,
    PendingStartups,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dashboardData() {
      return this.$store.getters['dealerDashboard/dashboardData']
    },
  },
  created() {
    this.getDashboardData()
  },
  methods: {
    getDashboardData() {
      this.$store.dispatch('dealerDashboard/getDashboardData')
    },
  },
}
</script>

<style scoped>

</style>
