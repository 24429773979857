<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="$t('Hoş Geldin') + ', ' + userData.username">
          Girişimcilik dünyasının buluşma noktası in4Startups'ya hoşgeldiniz!
          <div class="font-weight-bold mt-2 text-primary">
            in4starups Ekibi
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="success"
            size="45"
          >
            <feather-icon
              size="21"
              icon="CheckIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              1
            </h2>
            <span>{{ $t('Onaylanan Girişim') }}</span>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="info"
            size="45"
          >
            <feather-icon
              size="21"
              icon="ClockIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              13
            </h2>
            <span>{{ $t('Bekleyen Başvuru') }}</span>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="danger"
            size="45"
          >
            <feather-icon
              size="21"
              icon="AwardIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              41
            </h2>
            <span>{{ $t('Yatırımcı') }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="4">
        <b-card :title="$t('Son Eklenen Girişimler')">
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in dashboardData.latest_startups"
              :key="index"
              :href="'girisimler/' + item.seourl"
            >
              <b-row>
                <b-col cols="auto">
                  <b-avatar
                    :text="item.avatar_text"
                    size="64"
                    variant="primary"
                  />
                </b-col>
                <b-col>
                  <div class="font-weight-bold text-primary">
                    {{ item.title }}
                  </div>
                  <div class="font-weight-light">
                    {{ item.company }}
                  </div>
                  <div class="font-small-2">
                    {{ item.description }}
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col lg="8">
        <latest-blog-posts :blog-data="dashboardData.latest_blog_posts" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAvatar, BCard, BCol, BListGroup, BListGroupItem, BRow, BCardText,
} from 'bootstrap-vue'
import LatestBlogPosts from '@/views/Dashboard/components/LatestBlogPosts.vue'

export default {
  name: 'Startup',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BCardText,
    LatestBlogPosts,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dashboardData() {
      return this.$store.getters['appDashboard/getDashboardData']
    },
  },
  created() {
    this.getDashboardData()
  },
  methods: {
    getDashboardData() {
      this.$store.dispatch('appDashboard/dashboardDataList')
    },
  },
}
</script>

<style scoped>

</style>
