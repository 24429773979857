<template>
  <div>
    <b-card class="text-center">
      <b-avatar
        class="mb-1"
        :variant="color"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ countValue }}
        </h2>
        <span>{{ label }}</span>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  name: 'Counters',
  components: {
    BCard, BAvatar,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    countValue: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
