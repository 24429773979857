<template>
  <div>
    <b-card title="in4s Blog">
      <p>Yatırımcı dünyasına hoş geldin! Son içeriklerimize göz atmayı unutma.</p>
      <b-row>
        <b-col
          v-for="post in blogData"
          :key="post.id"
          md="4"
        >
          <b-link
            :href="post.url"
            target="_blank"
            class="text-muted"
          >
            <b-card
              :img-src="post.cover"
              img-top
              img-alt="card img"
              :title="post.title"
              class="mt-2"
            >
              <b-card-text>
                <div v-html="post.summary" />
              </b-card-text>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BLink,
} from 'bootstrap-vue'

export default {
  name: 'LatestStartups',
  components: {
    BCard, BRow, BCol, BCardText, BLink,
  },
  props: {
    blogData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
