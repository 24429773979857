<template>
  <div>
    <div
      v-if="dashboardData.notices"
      class="alerts"
    >
      <b-alert
        v-for="(notice, key) in dashboardData.notices"
        :key="key"
        variant="danger"
        show
        class="p-1"
      >
        <div class="alert-body">
          <span class="d-block"><FeatherIcon icon="InfoIcon" /> {{ notice }}</span>
        </div>
      </b-alert>
    </div>
    <b-row class="match-height">
      <b-col md="6">
        <b-card :title="$t('Hoş Geldin') + ', ' + userData.username">
          Girişimcilik dünyasının buluşma noktası in4Startups'ya hoşgeldiniz!
          <div class="font-weight-bold mt-2 text-primary">
            in4starups Ekibi
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card :title="$t('Üyelik Bilgisi')">
          <b-row>
            <b-col md="6">
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="success"
                    >
                      <feather-icon
                        size="18"
                        icon="CheckIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ dashboardData.package.package }}
                    </h6>
                    <small>{{ $t('Aktif Paketiniz') }}</small>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              v-if="dashboardData.package"
              md="6"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="info"
                    >
                      <feather-icon
                        size="18"
                        icon="ClockIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ moment(dashboardData.package.edate).format('LL') }}
                    </h6>
                    <small>{{ $t('Bitiş Tarihi') }}</small>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="EyeIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ dashboardData.startups }}
            </h2>
            <span>{{ $t('Yatırıma Açık Girişim') }}</span>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="success"
            size="45"
          >
            <feather-icon
              size="21"
              icon="BarChart2Icon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ dashboardData.investors }}
            </h2>
            <span>{{ $t('Yatırımcı') }}</span>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            class="mb-1"
            variant="danger"
            size="45"
          >
            <feather-icon
              size="21"
              icon="AwardIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              41
            </h2>
            <span>{{ $t('Girişim Başvurusu') }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="4">
        <b-card :title="$t('Son Eklenen Girişimler')">
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in dashboardData.latest_startups"
              :key="index"
              :to="'/girisimler/' + item.seourl"
            >
              <b-row>
                <b-col cols="auto">
                  <b-avatar
                    :text="item.avatar_text"
                    size="64"
                    variant="primary"
                  />
                </b-col>
                <b-col>
                  <div class="font-weight-bold text-primary">
                    {{ item.title }}
                  </div>
                  <div class="font-weight-light">
                    {{ item.company }}
                  </div>
                  <div class="font-small-2">
                    {{ item.description }}
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col lg="8">
        <latest-blog-posts :blog-data="dashboardData.latest_blog_posts" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAvatar, BCard, BCol, BListGroup, BListGroupItem, BRow, BMedia, BMediaAside, BMediaBody, BAlert,
} from 'bootstrap-vue'
import LatestBlogPosts from '@/views/Dashboard/components/LatestBlogPosts.vue'

export default {
  name: 'Investor',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    LatestBlogPosts,
    BAlert,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dashboardData() {
      return this.$store.getters['appDashboard/getDashboardData']
    },
  },
  created() {
    this.getDashboardData()
  },
  methods: {
    getDashboardData() {
      this.$store.dispatch('appDashboard/dashboardDataList')
    },
  },
}
</script>

<style scoped>

</style>
