<template>
  <div>
    <component
      :is="component"
      v-if="component"
    />
    <b-modal
      id="modal-welcome"
      v-model="modalShow"
      centered
      title="Hoşgeldiniz"
      ok-only
      hide-header
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      ok-title="Accept"
    >
      <b-card-text>
        <h3 class="mb-1 font-weight-bolder">
          {{ $t('Sizi Hangisi Tanımlar?') }}
        </h3>
        <p>In4Startups yatırımcı ve girişimcinin bir araya gelmesini sağlar. Kitlesel fonlama platformu değildir. Uygun yatırımcılar ve yatırım kuruluşları ile teknoloji girişimlerinin bir araya gelmesi için çalışır.</p>
        <b-row>
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              @click="selectType(1)"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Girişimci') }}</span>
            </b-button>
          </b-col>
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="selectType(2)"
            >
              <feather-icon
                icon="BarChart2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Yatırımcı') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Startup from '@/views/Dashboard/Startup.vue'
import Investor from '@/views/Dashboard/Investor.vue'
import Manager from '@/views/Dashboard/Manager.vue'
import Dealer from '@/views/Dashboard/Dealer.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    BCardText,
    Startup,
    Investor,
    Manager,
    Dealer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      component: null,
      locale: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
      modalShow: false,
    }
  },
  computed: {
    memberTypeSaveStatus() {
      return this.$store.getters['profile/getProfileSaveStatus']
    },
  },
  watch: {
    memberTypeSaveStatus(val) {
      if (val.status) {
        localStorage.setItem('userData', JSON.stringify(this.userData))
        if (this.userData.id_member_type === 1) {
          // this.$router.push('/girisimci-basvurusu')
          window.location.href = '/girisimci-basvurusu'
        }
        if (this.userData.id_member_type === 2) {
          // this.$router.push('/yatirimci-profili/yeni-olustur')
          window.location.href = '/yatirimci-profili/yeni-olustur'
        }
      }
    },
  },
  created() {
    this.dashboardType()
    this.memberControl()
  },
  methods: {
    dashboardType() {
      if (this.userData.type === 'member') {
        if (this.userData.id_member_type === '1') {
          this.component = 'Startup'
        }
        if (this.userData.id_member_type === '2') {
          this.component = 'Investor'
        }
        if (this.userData.id_member_type === '3') {
          this.component = 'Dealer'
        }
      }
      if (this.userData.type === 'admin') {
        this.component = 'Manager'
      }
    },
    memberControl() {
      if (this.userData.id_member_type === null) {
        this.modalShow = true
      }
    },
    selectType(val) {
      this.userData.id_member_type = val
      this.$store.dispatch('profile/selectedType', val)
      if (val === 1) {
        this.userData.user_type = this.$t('Girişimci')
      } else {
        this.userData.user_type = this.$t('Yatırımcı')
      }
      this.$store.dispatch('profile/profileSave', { id_member_types: val })
    },
  },
}
</script>

<style>

</style>
